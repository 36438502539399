import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import DatadogTransport from "@analytics/DatadogUtils";
import { useWebViewDetector } from "src/core/webview/WebViewController/useWebViewDetector";
import { DatadogWarnMessage } from "src/enums";
import { logoutSuccess as clearUserSession } from "src/features/signin/exports/state/actionCreators";
import { resetSessionDetails } from "state/actionCreators/sessionDetails";
import { RootState } from "state/delegate";
import { autoLoginSelectors, loginSelectors } from "state/selectors";
import { UserDataSecurityGateOpener } from "ui/UserDataSecurityGate/UserDataSecurityGateOpener";
import useSplashScreen from "ui/hooks/useSplashScreen";
import { useMount } from "utils/miniReactUse";

const userDataSecurityGateSelector = (state: RootState) => ({
  isAccount: loginSelectors.isLoggedIn(state),
  isAutoLoginSession: autoLoginSelectors.getIsAutoLoginSession(state),
});

export const UserDataSecurityGate: FC = ({ children }) => {
  const dispatch = useDispatch();

  const { isAccount, isAutoLoginSession } = useSelector(
    userDataSecurityGateSelector
  );

  const isWebView = useWebViewDetector();
  const isWebViewOnMount = useRef(isWebView);

  const [shouldIgnoreGateRules, setShouldIgnoreGateRules] = useState(false);
  const handleGateOpen = useCallback(() => setShouldIgnoreGateRules(true), []);

  const isGateOpen = useMemo(
    () =>
      shouldIgnoreGateRules ||
      !isAccount ||
      !isWebViewOnMount.current ||
      isAutoLoginSession,
    [isAccount, isAutoLoginSession, shouldIgnoreGateRules]
  );

  useMount(() => {
    if (isWebViewOnMount.current && !isAutoLoginSession && isAccount) {
      DatadogTransport.warn(DatadogWarnMessage.LOGOUT_FOR_SECURITY_REASONS);
      batch(() => {
        dispatch(clearUserSession());
        dispatch(resetSessionDetails());
      });
    }
  });

  useSplashScreen(!isGateOpen);

  return isGateOpen ? (
    <UserDataSecurityGateOpener onMount={handleGateOpen}>
      {children}
    </UserDataSecurityGateOpener>
  ) : null;
};
