import { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { UTM_PARAMS } from "src/constants";
import { sendVisitMarketing } from "src/features/marketing/api/marketing";
import {
  AdditionalDataPoints,
  MARKETING_PLATFORM,
  Nullable,
  RootState,
  SearchParams,
  afParameters,
  cleanLocalStorageByKeys,
  deviceInfoSelectors,
  getIrClickIdEnabled,
  getSnapchatEnabled,
  getTwitterMarketingEnabled,
  getVoluumEnabled,
  serverOwnedConfigSelectors,
  setAppsFlyerDirectLinkURL,
  setAppsFlyerOneLinkURL,
} from "src/features/marketing/imports";
import { marketingSelectors } from "src/features/marketing/state/selectors";
import { setMarketingVisitData } from "src/features/marketing/state/slice";
import {
  getBingMarketingEnabled,
  getFacebookMarketingEnabled,
  getGoogleMarketingEnabled,
  getTiktokMarketingEnabled,
  getUtmParamsEnabled,
} from "state/abTests";
import useIsPageForVisitor from "ui/navigation/hooks/useIsPageForVisitor";

const createPlatformPayload = (
  isEnabled: boolean,
  clickId: Nullable<string>,
  platform: MARKETING_PLATFORM
) => (isEnabled && clickId ? { [platform]: { clickId } } : {});

const selector = (state: RootState) => ({
  isIrClickIdEnabled: getIrClickIdEnabled(state),
  isVoluumEnabled: getVoluumEnabled(state),
  isSnapchatEnabled: getSnapchatEnabled(state),
  isTwitterMarketingEnabled: getTwitterMarketingEnabled(state),
  isFacebookMarketingEnabled: getFacebookMarketingEnabled(state),
  isBingMarketingEnabled: getBingMarketingEnabled(state),
  isGoogleMarketingEnabled: getGoogleMarketingEnabled(state),
  isTiktokMarketingEnabled: getTiktokMarketingEnabled(state),
  isUtmParamsEnabled: getUtmParamsEnabled(state),
  visitData: marketingSelectors.getVisitData(state),
  isServerConfigLoaded:
    serverOwnedConfigSelectors.getIsServerConfigLoaded(state),
  fingerprint: deviceInfoSelectors.getDeviceFingerprint(state),
  analyticsKey: marketingSelectors.getAnalyticsKey(state),
});

type GeneralParams =
  | {
      [MARKETING_PLATFORM.GENERAL]: {
        landing_url: string;
        referrer_url: string;
      };
    }
  | NonNullable<unknown>;

const useVisitMarketing = () => {
  const {
    isIrClickIdEnabled,
    isVoluumEnabled,
    isSnapchatEnabled,
    isTwitterMarketingEnabled,
    isFacebookMarketingEnabled,
    isBingMarketingEnabled,
    isGoogleMarketingEnabled,
    isTiktokMarketingEnabled,
    isUtmParamsEnabled,
    visitData,
    isServerConfigLoaded,
    fingerprint,
    analyticsKey,
  } = useSelector(selector, shallowEqual);

  const dispatch = useDispatch();

  const isVisitorPath = useIsPageForVisitor();

  const irClickId = localStorage.getItem(SearchParams.IR_CLICK_ID);
  const voluumClickId = localStorage.getItem(SearchParams.VOLUUM);
  const snapchatClickId = localStorage.getItem(SearchParams.SNAPCHAT);
  const twitterClickId = localStorage.getItem(SearchParams.TWITTER);
  const facebookClickId = localStorage.getItem(SearchParams.FACEBOOK_CLICK_ID);
  const bingClickId = localStorage.getItem(SearchParams.BING_CLICK_ID);
  const googleClickId = localStorage.getItem(SearchParams.GOOGLE_CLICK_ID);
  const tiktokClickId = localStorage.getItem(SearchParams.TIKTOK_CLICK_ID);
  const landing_url = localStorage.getItem(AdditionalDataPoints.LANDING_URL);
  const referrer_url = localStorage.getItem(AdditionalDataPoints.REFERRER_URL);

  const utmParams = UTM_PARAMS.reduce((acc, curr) => {
    const param = localStorage.getItem(curr);
    if (param) {
      return { ...acc, [curr]: localStorage.getItem(curr) };
    }

    return acc;
  }, {});

  const additionalMarketingParams: GeneralParams = useMemo(
    () =>
      isUtmParamsEnabled && Object.keys(utmParams).length !== 0
        ? {
            [MARKETING_PLATFORM.GENERAL]: {
              landing_url,
              referrer_url,
              ...utmParams,
            },
          }
        : {},
    [isUtmParamsEnabled, landing_url, referrer_url, utmParams]
  );

  useEffect(() => {
    if (analyticsKey && window?.AF_SMART_SCRIPT) {
      const afParametersWithAnalyticsKey = {
        afParameters: {
          ...afParameters,
          afCustom: [
            ...afParameters.afCustom,
            {
              paramKey: AdditionalDataPoints.ANALYTICS_ID,
              keys: [AdditionalDataPoints.ANALYTICS_ID],
              defaultValue: analyticsKey,
            },
          ],
        },
      };

      setAppsFlyerOneLinkURL(afParametersWithAnalyticsKey);
      setAppsFlyerDirectLinkURL(afParametersWithAnalyticsKey);
    }
  }, [analyticsKey, window?.AF_SMART_SCRIPT]);

  useEffect(() => {
    if (visitData.isVisitRequestNeeded && isServerConfigLoaded) {
      const platformPayload = {
        ...createPlatformPayload(
          isIrClickIdEnabled,
          irClickId,
          MARKETING_PLATFORM.IMPACT
        ),
        ...createPlatformPayload(
          isVoluumEnabled,
          voluumClickId,
          MARKETING_PLATFORM.VOLUUM
        ),
        ...createPlatformPayload(
          isSnapchatEnabled,
          snapchatClickId,
          MARKETING_PLATFORM.SNAPCHAT
        ),
        ...createPlatformPayload(
          isTwitterMarketingEnabled,
          twitterClickId,
          MARKETING_PLATFORM.TWITTER
        ),
        ...createPlatformPayload(
          isFacebookMarketingEnabled,
          facebookClickId,
          MARKETING_PLATFORM.FACEBOOK
        ),
        ...createPlatformPayload(
          isBingMarketingEnabled,
          bingClickId,
          MARKETING_PLATFORM.BING
        ),
        ...createPlatformPayload(
          isGoogleMarketingEnabled,
          googleClickId,
          MARKETING_PLATFORM.GOOGLE
        ),
        ...createPlatformPayload(
          isTiktokMarketingEnabled,
          tiktokClickId,
          MARKETING_PLATFORM.TIKTOK
        ),
        ...additionalMarketingParams,
      };

      if (Object.keys(platformPayload).length !== 0) {
        sendVisitMarketing({
          body: { deviceId: fingerprint, platformPayload },
          isVisitorPath,
        }).finally(() => {
          cleanLocalStorageByKeys([
            SearchParams.IR_CLICK_ID,
            SearchParams.VOLUUM,
            SearchParams.TWITTER,
            SearchParams.FACEBOOK_CLICK_ID,
            SearchParams.BING_CLICK_ID,
            SearchParams.GOOGLE_CLICK_ID,
            ...UTM_PARAMS,
          ]);
          dispatch(
            setMarketingVisitData({
              isVisitRequestNeeded: false,
            })
          );
        });
      }
    }
  }, [isServerConfigLoaded, visitData, isVisitorPath]);
};

export default useVisitMarketing;
