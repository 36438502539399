import { AdditionalDataPoints } from "@analytics/enums";
import {
  getAndroidAppId,
  getGetTheAppAppStoreUrl,
  getGetTheAppGooglePlayUrl,
  getIosAppId,
} from "environment";
import {
  APPS_FLYER_APP_STORE_DIRECT_LINK_URL,
  APPS_FLYER_GOOGLE_PLAY_DIRECT_LINK_URL,
  APPS_FLYER_ONE_LINK_URL,
} from "src/core/analytics/constants";
import { AppsFlyerPlatform } from "src/core/analytics/enums";

const oneLinkURL = "https://tango.onelink.me/RCIH";
const mediaSource = { keys: ["utm_source"], defaultValue: "Web_Organic" };
const deepLinkValue = {
  keys: ["deep_link_value"],
  defaultValue: "page_unknown",
};
const button_name = {
  paramKey: "button_name",
  keys: ["button_name"],
  defaultValue: "button_unknown",
};
const campaign = { keys: ["utm_campaign"], defaultValue: "Web_Campaign" };
const afSub1 = { keys: ["utm_content"], defaultValue: "Web_Content" };
const afSub2 = { keys: ["utm_medium"], defaultValue: "Web_Medium" };
const afSub3 = { keys: ["utm_term"], defaultValue: "Web_Term" };
const afSub4 = { keys: ["utm_id"], defaultValue: "Web_ID" };
const af_dp = {
  paramKey: "af_dp",
  keys: ["af_dp"],
  defaultValue: "tango%3A%2F%2F",
};
const googleClickIdParam = "af_sub5";

const sessionIdValue =
  localStorage.getItem(AdditionalDataPoints.SESSION_ID) || "session_id_unknown";

const session_id = {
  paramKey: AdditionalDataPoints.SESSION_ID,
  keys: [AdditionalDataPoints.SESSION_ID],
  defaultValue: sessionIdValue,
};

const custom_ss_ui = { paramKey: "af_ss_ui", defaultValue: "true" };

export const afParameters = {
  mediaSource,
  deepLinkValue,
  afCustom: [button_name, af_dp, session_id, custom_ss_ui],
  campaign,
  afSub1,
  afSub2,
  afSub3,
  afSub4,
  googleClickIdKey: googleClickIdParam,
};

export const setAppsFlyerOneLinkURL = (options = {}) => {
  const generatedOneLinkURL =
    window.AF_SMART_SCRIPT?.generateOneLinkURL({
      oneLinkURL,
      afParameters,
      ...options,
    })?.clickURL || "";

  localStorage.setItem(APPS_FLYER_ONE_LINK_URL, generatedOneLinkURL);
};

export const getAppsFlyerOneLinkURL = () =>
  localStorage.getItem(APPS_FLYER_ONE_LINK_URL);

const generateAppsFlyerDirectLinkURL = (
  platform: string,
  appid: string,
  redirectURL: string,
  options = {}
) =>
  window.AF_SMART_SCRIPT?.generateDirectClickURL({
    afParameters,
    platform,
    app_id: appid,
    redirectURL,
    ...options,
  })?.clickURL || "";

export const setAppsFlyerDirectLinkURL = (options = {}) => {
  const generatedGooglePlatDirectLinkURL = generateAppsFlyerDirectLinkURL(
    AppsFlyerPlatform.ANDROID,
    getAndroidAppId(),
    getGetTheAppGooglePlayUrl(),
    options
  );

  const generatedAppStoreDirectLinkURL = generateAppsFlyerDirectLinkURL(
    AppsFlyerPlatform.IOS,
    getIosAppId(),
    getGetTheAppAppStoreUrl(),
    options
  );

  localStorage.setItem(
    APPS_FLYER_GOOGLE_PLAY_DIRECT_LINK_URL,
    generatedGooglePlatDirectLinkURL
  );

  localStorage.setItem(
    APPS_FLYER_APP_STORE_DIRECT_LINK_URL,
    generatedAppStoreDirectLinkURL
  );
};

export const handleGetTheAppGooglePlayUrl = () =>
  localStorage.getItem(APPS_FLYER_GOOGLE_PLAY_DIRECT_LINK_URL) ||
  getGetTheAppGooglePlayUrl();

export const handleGetTheAppAppStoreUrl = () =>
  localStorage.getItem(APPS_FLYER_ONE_LINK_URL) || getGetTheAppAppStoreUrl();
